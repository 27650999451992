import {apiGetAuth, apiPostAuth, apiPatchAuth} from '@/utils/api'
import { API_HELPDESK, API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    helpCenter: { data: [] },
    exportHelpCenter: { data: [] },
    listCategoryHc: { data: [] },
    listTypeHc: { data: [] },
    detail_help_center: { data: {}},
    bulk_uploads: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    assignee: { data: [] },
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_LIST_CATEGORY_HC (state, payload) {
    state.listCategoryHc = payload
  },
  SET_LIST_ROLE(state, payload) {
    state.listRole = payload
  },
  SET_LIST_CHANNEL(state, payload) {
    state.listChannel = payload
  },
  SET_LIST_TYPE_HC (state, payload) {
    state.listTypeHc = payload
  },
  SET_HELP_CENTER (state, payload) {
    state.helpCenter = payload
  },
  SET_ASSIGNEE (state, payload) {
    state.assignee = payload
  },

  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_EXPORT_HELP_CENTER (state, payload) {
    state.exportHelpCenter = payload
  },
  SET_DETAIL_HELP_CENTER (state, payload) {
    state.detail_help_center = payload
  },
  SET_LIST_BULK_UPLOAD (state, payload) {
    state.bulk_uploads = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListCategoryHc (context, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_CATEGORY, payload)
      context.commit('SET_LIST_CATEGORY_HC', response.data.data.data)
      return response.data.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListRole (context, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_ROLE, payload)
      context.commit('SET_LIST_ROLE', response.data.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListChannel (context, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_CHANNEL, payload)
      context.commit('SET_LIST_CHANNEL', response.data.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListAssignees(_, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_ASSIGNEE+`?search=${payload}`)
      
      return response.data.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListHelpCenter (context, payload) {
    
    try {
      const response = await apiGetAuth(
        API_HELPDESK.LIST_TICKET +
          `?page=${payload.page || ''}&limit=${payload.limit || ''}&search=${payload.search || ''}` +
          `&created_at_from=${payload.created_at_from || ''}&created_at_to=${payload.created_at_to || ''}` +
          `&status=${payload.status || ''}&category_id=${payload.category_id || ''}&assign_to_user_id=${payload.assign_to_user_id || ''}`
      )

      context.commit('SET_HELP_CENTER', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'HelpCenter', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createHelpCenter (context, payload) {
    try {
      const response = await apiPostAuth(API_HELPDESK.LIST_TICKET, payload)
      console.log(response)
      
      router.push({ name: 'HelpCenter', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailHelpCenter (context, id) {
    try {
      const response = await apiGetAuth(API_HELPDESK.DETAIL_TICKET(id))
      
      context.commit('SET_DETAIL_HELP_CENTER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async solvedHelpCenter (context, payload) {
    try {
      const response = await apiPatchAuth(API_HELPDESK.UPDATE_TICKET(payload.id), payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', { })
      notificationDanger(error)
    }
  },
  async solvedHelpCenterBulk(context, payload) {
    try {
      const response = await apiPatchAuth(API_HELPDESK.BULK_UPDATE_TICKET, payload)
      context.commit('SET_LIST_BULK_UPLOAD', response.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', { })
      notificationDanger(error)
    }
  },
  async getListAssignee(context, id) {
    try {
      const response = await apiGetAuth(API_HELPDESK.CREATE_ASSIGNEE(id))
      context.commit('SET_ASSIGNEE', response.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', { })
      notificationDanger(error)
    }
  },
  async createAssignee(context, payload) {
    
    try {
      const response = await apiPostAuth(API_HELPDESK.CREATE_ASSIGNEE(payload.id), payload)
      context.commit('SET_ASSIGNEE', response.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', { })
      notificationDanger(error)
    }
  },
  async updateAssignee(context, payload) {
    try {
      const response = await apiPatchAuth(API_HELPDESK.UPDATE_ASSIGNEE(payload.id, payload.ticket_id), payload)
      context.commit('SET_ASSIGNEE', response.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', { })
      notificationDanger(error)
    }
  },
  async getDetailProfile () {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_PROFILE)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_STORE, payload)
      context.commit('SET_STORE', response.data.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_category_hc (state) {
    return state.listCategoryHc
  },
  list_role (state) {
    return state.listRole
  },
  list_channel (state) {
    return state.listChannel
  },
  list_type_hc (state) {
    return state.listTypeHc
  },
  list_help_center (state) {
    return state.helpCenter
  },
  list_bulk_uploads (state) {
    return state.bulk_uploads
  },
  export_help_center (state) {
    return state.exportHelpCenter
  },
  detail_help_center (state) {
    return state.detail_help_center
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  list_assignee (state) {
    return state.assignee
  },
  list_store (state) {
    return state.store
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
